<template>
    <div id="association">
		<v-head title="醫學會"></v-head>
		<van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
			<div ref="listView">
				<div class="association_activity-banner">
				</div>
				<div class="association-list">
					<div class="association-item" v-for="association in associationList" :key="association.associationid">
						<img class="association-logo" :src="association.logourl" :alt="association.name">
						<div class="row">
							<a class="btn-association-link" :href="association.domain" target="_blank">學會網站</a>
                        	<a class="btn-join-association" :href="`${association.domain}/Association/JoinUs`" target="_blank">申請加入學會</a>
						</div> 
					</div>
				</div>
				<div class="filter-association" @click="show = true">
					<span class="hint">篩選學會課程</span>
					<span class="value" data-value="" v-text="associationTitle"></span>
				</div>
				<ul class="activity-sortby-items">
					<li :class="{'activity-sortby-item': true, 'current': tabIndex == 0}" @click="changeTabIndex(0)">所有課程</li>
					<li :class="{'activity-sortby-item': true, 'current': tabIndex == 1}" @click="changeTabIndex(1)">未進行</li>
					<li :class="{'activity-sortby-item': true, 'current': tabIndex == 2}" @click="changeTabIndex(2)">進行中</li>
					<li :class="{'activity-sortby-item': true, 'current': tabIndex == 3}" @click="changeTabIndex(3)">已結束</li>
				</ul>
				<div class="activity-list">
					<div :class="{'activity-item': true, [activity.step]: true}" v-for="activity in activityList" :key="activity.activityid">
						<div class="activity-pic-wrapper">
							<img :src="activity.coverpicurl" alt="" :class="{'activity-pic': true, 'hide': !activity.coverpicurl}">
						</div>
						<div class="activity-item-r">
							<h2 class="title"><a :href="activity.activitydetailurl" v-text="activity.title"></a></h2>
							<div class="activity-c-time" v-text="activity.starttime + '至' +activity.endtime"></div>
							<div class="association-name" v-text="activity.associationname"></div>
						</div>
						<div class="btn-status" v-text="activity.statusText"></div>
					</div>
					<loading v-if="loadingActivity" class="loading-box-1"/>
				</div>
				<div class="load-more" v-if="isLoadMore">
					<div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
					<span class="text">正在加载...</span>
				</div>
				<div class="load-finished" v-if="isLoadFinished">已全部加載</div>
				<loading v-if="loading" class="loading-box-1"/>
			</div>
		</van-pull-refresh>
		<van-popup
            v-model="show"
            closeable
            position="bottom"
            :style="{ height: '30%' }"
        >
            <div class="association-options">
				<div class="association-option" @click="onAssociationOptionClick(null, '全部學會')">全部學會</div>
				<div class="association-option"
					v-for="association in associationList" 
					:key="association.associationid" 
					v-text="association.name"
					@click="onAssociationOptionClick(association.associationid, association.name)"
				>
				</div>
			</div>
        </van-popup>
    </div>
</template>

<script>
	import association from '@/views/mixins/association'
	import vHead from '@/layouts/MobileHead.vue'
	import Loading from '@/layouts/Loading.vue'
	export default {
		mixins: [ 
			association,
		],
		components: {
			vHead,
			Loading
		},
		data: function(){
			return {
				show: false,
				isRefresh: false,
				loading: true,
				loadingActivity: false,
				associationList: [],
				activityList: [],
				finalActivityList: [],
				pageNo: 1,
				pageSize: 10,
				maxPage: 0,
				isLoadMore: false,
				isLoadFinished: false,
				tabIndex: 0,
				associationId: null,
				associationTitle: '全部學會',
			}
		},
		mounted: function(){
			this.onRefresh()
		},
		methods: {
			joinAssociation: function(id){

			},
			onRefresh: function(){
				this.loadAssociationList((rs, error)=>{
					if(!error) {
						this.associationList = (rs || []).filter((d,i)=>i<2)
					}
					this.pageNo = 1
					this.loadActivityList({
						langcode: 'GB',
						associationid: this.associationId || undefined,
						pageNo: this.pageNo,
						pageSize: this.pageSize,
						type: this.tabIndex || undefined,
					}, (data, error)=>{
						this.loading = false
						this.isRefresh = false
						this.loadingActivity = false
						this.activityList = (data && data.list) || []
						this.finalActivityList = [...this.activityList]
						var total = (data && data.total) || 0
						if(total > 0) {
							this.maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
							if(this.pageNo >= this.maxPage) {
								this.isLoadFinished = true
							}
						} else {
							if(!error) {
								this.isLoadFinished = true
							}
						}
					})
				})
			},
			handleScroll (event) {
				if(this.$refs.listView.clientHeight < window.innerHeight) return
				if((window.innerHeight + window.pageYOffset) > this.$refs.listView.clientHeight) {
					// 加载更多
					if(!this.loading && !this.isLoadMore && this.pageNo < this.maxPage) {
						this.isLoadMore = true
						this.loading = true
						this.loadActivityList({
							langcode: 'GB',
							associationid: this.associationId || undefined,
							pageNo: this.pageNo,
							pageSize: this.pageSize,
							type: this.tabIndex || undefined,
						}, (data, error)=>{
							this.loading = false
							this.isLoadMore = false
							this.activityList = this.activityList.concat((data && data.list) || [])
							if(!error) {
								this.pageNo = this.pageNo + 1
								var total = (data && data.total) || 0
								if(total > 0) {
									var maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
									if(this.pageNo >= maxPage) {
										this.isLoadFinished = true
									}
								}
							}
						})
					}
				}
			},
			changeTabIndex: function(tabIndex) {
				this.tabIndex = tabIndex
				this.loadingActivity = true
                this.onRefresh()
			},
			onAssociationOptionClick: function(v, title) {
				this.show = false
				if(this.associationId != v) {
					this.associationId = v
					this.associatioitle = title
					this.loadingActivity = true
					this.onRefresh()
				}
			}
		},
		created () {
			window.addEventListener('scroll', this.handleScroll);
		},
		destroyed () {
			window.removeEventListener('scroll', this.handleScroll);
		},
	};
</script>

<style lang="scss" scoped>
	#association {
	   width: 100%;
	   padding-top: 83px;
   	}

    .association_activity-banner {
        display: block;
        margin: 11px 24px 0px 24px;
        background-image: url(../../../assets/imgs/mobile/association_activity/img_bannerxh@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 194px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .association-list {
        display: flex;
        flex-direction: row;
        height: 163px;
        margin-top: 16px;
    }

    .association-list > .association-item {
        width: 50%;
        height: 163px;
        background-color: #fff;
        margin-left: 23px;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .association-list > .association-item > .association-logo {
        width: 258px;
        height: 48px;
        display: block;
        margin: 19px auto 0px auto;
    }

    .association-list > .association-item > .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin-top: 36px;
    }
    
    .association-list > .association-item > .row > .btn-join-association,
    .association-list > .association-item > .row > .btn-association-link {
        height: 40px;
        line-height: 40px;
		padding: 0px 8px;
        background-color: #FFE3D5;
        color: #FF6A21;
        font-size: 24px;
        font-weight: 400;
        text-align: center;
        border-radius: 4px;
    }

    .association-list > .association-item > .row > .btn-join-association {
        left: 192px;
        color: #36C4D0;
        background-color: #DCF7FB;
    }

    .filter-association {
        display: flex;
        flex-direction: row;
        height: 90px;
        background-color: #fff;
        margin: 16px 24px;
        position: relative;
        align-items: center;
        padding: 0px 39px 0px 16px;
        justify-content: space-between;
    }

    .filter-association::after {
        content: '';
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        width: 8px;
        height: 17px;
        background-image: url(../../../assets/imgs/mobile/association_activity/icon_xuehui@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .filter-association > .hint {
        color: #636363;
        font-size: 28px;
        font-weight: 400;
        line-height: 26px;
    }

    .filter-association > .value {
        color: #231F20;
        font-size: 36px;
        font-weight: bold;
        line-height: 34px;
    }

    .activity-sortby-items {
        display: flex;
        flex-direction: row;
        height: 90px;
        background-color: #fff;
        margin-left: 24px;
        margin-right: 24px;
        align-items: flex-start;
        padding-top: 28px;
        box-sizing: border-box;
        justify-content: space-around;
    }

    .activity-sortby-items > .activity-sortby-item {
        height: 34px;
        line-height: 34px;
        color: #636363;
        font-weight: 400;
        font-size: 28px;
        position: relative;
        text-align: left;
    }

    .activity-sortby-items > .activity-sortby-item.current {
        color: #231F20;
        font-size: 36px;
        font-weight: bold;
    }

    .activity-sortby-items > .activity-sortby-item.current::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -18px;
        transform: translateX(-50%);
        width: 48px;
        height: 10px;
        border-radius: 5px;
        background-color: #36C4D0;
    }

    .activity-list {
        display: flex;
        flex-direction: column;
		position: relative;
    }

    .activity-list > .activity-item {
        display: flex;
        flex-direction: row;
        height: 202px;
        background-color: #fff;
        padding: 16px;
        box-sizing: border-box;
        margin: 0px 24px;
        margin-top: 1px;
        position: relative;
    }

    .activity-list > .activity-item.step2 {
        background-color: #F4F4F4;
    }

    .activity-list > .activity-item > .activity-pic-wrapper {
		display: block;
        min-width: 180px;
        height: 170px;
        margin-right: 19px;
        border-radius: 16px;
        align-self: center;
        background-color: #ddd;
		position: relative;
    }

    .activity-list > .activity-item > .activity-pic-wrapper > .activity-pic {
        width: 180px;
        height: 170px;
        border-radius: 16px;
        vertical-align: top;
    }

    .activity-list > .activity-item > .activity-pic-wrapper > .activity-pic.hide {
        display: none;
    }

    .activity-list > .activity-item.step2 > .activity-pic-wrapper::after {
        content: '';
        position: absolute;
        left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
        width: 141px;
        height: 124px;
        background-image: url(../../../assets/imgs/mobile/association_activity/img_wanjie@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .activity-list > .activity-item > .activity-item-r {
        display: flex;
        flex-direction: column;
    }

    .activity-list > .activity-item > .activity-item-r > .title {
        font-size: 34px;
        color: #231F20;
        font-weight: bold;
        line-height: 36px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin-top: 16px;
    }

    .activity-list > .activity-item > .activity-item-r > .activity-c-time {
        font-size: 24px;
        color: #969696;
        font-weight: 400;
        line-height: 21px;
        margin-top: 42px;
    }

    .activity-list > .activity-item > .activity-item-r > .association-name {
        font-size: 24px;
        color: #231F20;
        font-weight: 400;
        line-height: 23px;
        margin-top: 20px;
    }

    .activity-list > .activity-item > .btn-status {
        position: absolute;
        right: 15px;
        bottom: 25px;
        height: 36px;
        line-height: 36px;
        background-color: #DCF7FB;
        border-radius: 18px;
        padding: 0px 12px;
        color: #36C4D0;
        font-size: 24px;
        font-weight: 400;
    }

    .activity-list > .activity-item.step0 > .btn-status {
        background-color: #FFF1E2;
        color: #FF8000;
    }

    .activity-list > .activity-item.step2 > .btn-status {
        background-color: #E8E8E8;
        color: #231F20;
    }

	.loading-box-1 {
		padding-top: 250px;
	}

	// loadmore
	.load-more {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.load-more > .text {
		margin-left: 24px;
	}

	.load-finished {
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		font-size: 24px;
		font-weight: 400;
		color: #99999991;
	}

	.load-finished::before {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		right: -130px;
		margin-right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}

	.load-finished::after {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		left: -130px;
		top: 50%;
		margin-left: 20px;
		transform: translateY(-50%);
	}

	.association-options {
		padding-top: 80px;
	}

	.association-options > .association-option {
		height: 72px;
		line-height: 72px;
		margin-left: 24px;
		margin-right: 24px;
		border-bottom: 1px solid #e2e2e2;
		font-size: 28px;
		font-weight: 400;
		color: #231F20;
		background-color: #fff;
	}

	.association-options > .association-option:active {
		opacity: 0.7;
	}


	.lds-default {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-default div {
		position: absolute;
		width: 6px;
		height: 6px;
		background: #36C4D0;
		border-radius: 50%;
		animation: lds-default 1.2s linear infinite;
	}
	.lds-default div:nth-child(1) {
		animation-delay: 0s;
		top: 37px;
		left: 66px;
	}
	.lds-default div:nth-child(2) {
		animation-delay: -0.1s;
		top: 22px;
		left: 62px;
	}
	.lds-default div:nth-child(3) {
		animation-delay: -0.2s;
		top: 11px;
		left: 52px;
	}
	.lds-default div:nth-child(4) {
		animation-delay: -0.3s;
		top: 7px;
		left: 37px;
	}
	.lds-default div:nth-child(5) {
		animation-delay: -0.4s;
		top: 11px;
		left: 22px;
	}
	.lds-default div:nth-child(6) {
		animation-delay: -0.5s;
		top: 22px;
		left: 11px;
	}
	.lds-default div:nth-child(7) {
		animation-delay: -0.6s;
		top: 37px;
		left: 7px;
	}
	.lds-default div:nth-child(8) {
		animation-delay: -0.7s;
		top: 52px;
		left: 11px;
	}
	.lds-default div:nth-child(9) {
		animation-delay: -0.8s;
		top: 62px;
		left: 22px;
	}
	.lds-default div:nth-child(10) {
		animation-delay: -0.9s;
		top: 66px;
		left: 37px;
	}
	.lds-default div:nth-child(11) {
		animation-delay: -1s;
		top: 62px;
		left: 52px;
	}
	.lds-default div:nth-child(12) {
		animation-delay: -1.1s;
		top: 52px;
		left: 62px;
	}
	@keyframes lds-default {
		0%, 20%, 80%, 100% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.5);
		}
	}
</style>